.listItem {
  width: 95%;
  margin-top: 5px;
  padding: 30px;
  opacity: 1;
  transition: all 0.2s;
}

.listItemDraggingOver {
  scale: 1.02;
}

.listItemDragging {
  opacity: 0.5;
}
